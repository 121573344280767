import React from "react";
import Head from "next/head";
import { ApolloProvider } from "@apollo/react-hooks";
import { theme, ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/node";
import dayjs from "dayjs";
import apolloClient from "@src/services/apolloClient";
import { AppProps } from "next/app";

dayjs.locale("ja");

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.SENTRY_DSN,
});

const customTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    body: "source-han-sans-japanese, sans-serif",
  },
};

const App = ({ Component, pageProps }: AppProps) => {
  return (
    // TODO: Fix type mismatch
    // Error: Property 'setLink' is missing in type 'ApolloClient<NormalizedCacheObject>' but required in type 'ApolloClient<any>
    <ApolloProvider client={apolloClient as any}>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Head>
      <ChakraProvider theme={customTheme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </ApolloProvider>
  );
};

export default App;
